import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Blog from './pages/Blog';
import Prev from './pages/Prev';

import Byt from './pages/blog/Byt';
import DataInfra from './pages/blog/DataInfra';
import Duolingo from './pages/blog/Duolingo';
import Facebook from './pages/blog/Facebook';
import GamblersRuin from './pages/blog/GamblersRuin';
import Netflix from './pages/blog/Netflix';
import ReviewPrediction from './pages/blog/ReviewPrediction';
import PhotosPage from './pages/Life';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/about" element={<Prev />} />

          <Route path="/byt" element={<Byt />} />
          <Route path="/Data-Infra" element={<DataInfra />} />
          <Route path="/Duolingo" element={<Duolingo />} />
          <Route path="/facebook" element={<Facebook />} />
          <Route path="/Gamblers-Ruin" element={<GamblersRuin />} />
          <Route path="/netflix" element={<Netflix />} />
          <Route path="/Review-Prediction" element={<ReviewPrediction />} />
          <Route path="/moments" element={<PhotosPage />} />
          
        </Routes>
      </div>
    </Router>
  );
}

export default App;
