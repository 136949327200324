import React from 'react';
import { format } from 'date-fns';
import { Image } from 'react-image';
import { Link } from 'react-router-dom';

// Dynamically import all images ending with .JPG, .PNG, or .JPEG
const importAll = (requireContext) => requireContext.keys().map(requireContext);
const images = importAll(require.context('../assets/photos/photos2', false, /\.(jpe?g|png)$/i));

// Tuple array for dates and locations
const photoDetails = [
  { date: '2024-01-19', location: 'Philadelphia, PA' },
  { date: '2022-07-12', location: 'Sunnyvale, CA' },
  { date: '2022-10-08', location: 'Boston, MA' },
  { date: '2024-06-17', location: 'New York, NY' },
  { date: '2024-12-02', location: 'Boston, MA' },
  { date: '2024-12-03', location: 'Philadelphia, PA' },
  { date: '2024-12-05', location: 'Philadelphia, PA' },
  { date: '2024-03-07', location: 'Turin, Italy' },
  // { date: '2024-03-03', location: 'Andermatt, Switzerland' },
  { date: '2024-03-05', location: 'Car Mechanic, Andermatt, Switzerland' },
  { date: '2023-05-11', location: 'Wayzata, MN' },
  { date: '2023-05-31', location: 'Sausalito, Italy' },
  { date: '2022-04-27', location: 'Philadelphia, PA' },
  { date: '2024-12-06', location: 'Philadelphia, PA' },
  // { date: '2024-12-10', location: 'Philadelphia, PA' },
  { date: '2023-05-16', location: 'Sorrento, Italy' },
  { date: '2023-07-01', location: 'San Francisco, CA' },
  { date: '2024-10-01', location: 'San Francisco, CA' },
  { date: '2022-08-06', location: 'San Jose, CA' },
  { date: '2022-10-07', location: 'Boston, MA' },
  { date: '2023-09-28', location: 'Philadelphia, PA' },
  // { date: '2024-08-17', location: 'San Francisco, CA' },
  { date: '2023-04-01', location: 'Philadelphia, PA' },
  { date: '2024-11-16', location: 'Philadelphia, PA' },
  { date: '2023-05-20', location: 'Annecy, France' },
  // { date: '2024-01-01', location: 'Menlo Park, CA' },
  { date: '2024-03-15', location: 'Philadelphia, PA' },
  { date: '2023-11-18', location: 'Philadelphia, PA' },
  { date: '2022-05-14', location: 'Wayzata, MN' },
  { date: '2022-06-18', location: 'San Francisco, CA' },
  { date: '2024-05-19', location: 'Porto, Portugal' },
  { date: '2024-01-13', location: 'Yellowknife, Canada' },
];

// Sample photo data with years and dates
const photoData = images.map((image, index) => {
  const date = photoDetails[index]?.date;
  const formattedDate = date ? format(new Date(date), "MMMM do, yyyy") : `A beautiful day`;
  const year = date ? new Date(date).getFullYear() : 2024;
  return {
    src: image,
    date: formattedDate,
    year: year,
    location: photoDetails[index]?.location || `Somewhere wonderful`
  };
});

// Group photos by year 
const photosByYear = photoData.reduce((acc, photo) => {
  if (!acc[photo.year]) {
    acc[photo.year] = [];
  }
  acc[photo.year].push(photo);
  return acc;
}, {});

// Sort photos by date within each year
Object.keys(photosByYear).forEach(year => {
  photosByYear[year].sort((a, b) => {
    const dateA = new Date(photoDetails.find(detail => format(new Date(detail.date), "MMMM do, yyyy") === a.date)?.date);
    const dateB = new Date(photoDetails.find(detail => format(new Date(detail.date), "MMMM do, yyyy") === b.date)?.date);
    return dateB - dateA;
  });
  
});

function PhotosPage() {
  return (
    <div className="bg-black min-h-screen text-white overflow-y-auto">
      <div className="max-w-4xl mx-auto px-4 pt-12 pb-6">
        <h1 className="text-xl text-center mb-2 text-white">
          moments captured
        </h1>

        <h1 className="text-sm text-center mb-10 text-white italic">
          it's about the friends we made along the way
        </h1>

        {Object.entries(photosByYear)
          .sort(([yearA], [yearB]) => parseInt(yearB) - parseInt(yearA))
          .map(([year, yearPhotos]) => (
            <div key={year} className="mb-10 px-4">
              <h2 className="text-3xl font-semibold mb-2 text-gray-300 border-b border-gray-700 pb-2 border-none">
                {year}
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {yearPhotos.map((photo, index) => (
                  <div 
                    key={index} 
                    className="group flex flex-col"
                  >
                    <div className="max-w-full overflow-hidden shadow-lg">
                      <img 
                        src={photo.src} 
                        alt={`Photo from ${photo.date}`} 
                        className="w-full h-auto object-contain rounded-none"
                        loading="lazy"
                      />
                    </div>
                    <p className="text-sm text-gray-300 mt-2">
                      {photo.date} - <em>{photo.location}</em>
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}

      <div className="text-center">
        <p className="text-zinc-500 mx-2">[ <a className="hover:text-zinc-700" href="/">home</a> ]</p>
      </div>
      </div>
      
      
    </div>
  );
}

export default PhotosPage;