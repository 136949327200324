import React from 'react';

function Experience() {
  return (
    <div className="flex flex-col justify-between items-center min-h-screen bg-zinc-950 text-zinc-600 text-md px-4">
      <div className="flex-grow flex flex-col justify-center max-w-xl px-4 py-8 animate-fadein">
        <h2 className="text-xl font-bold mb-1">Current</h2>
        <p className='mb-3'></p>
        <p className="mb-4">Building <a className="underline hover:text-zinc-600" href='https://www.trycronus.com' target='_blank'>something new</a> + completing a BS in Finance and Statistics @ Wharton and an MS in Computer Science @ Penn</p>
       
        <h2 className="text-xl font-bold mb-1">Past</h2>
        <ul>
        <li className="mb-3">
          <p className='text-zinc-700'><span className='font-semibold text-zinc-600'>Software Engineering Intern @ Google</span>, worked on Gmail suspicious user detection systems</p>
          </li>
          <li className="mb-3">
          <p className='text-zinc-700'><span className='font-semibold text-zinc-600'>Technology Private Equity Summer Analyst @ <a className="underline hover:text-zinc-600" href='https://www.silverlake.com/' target='_blank'>Silver Lake</a></span></p>
          </li>
          <li className="mb-3">
          <p className='text-zinc-700'><span className='font-semibold text-zinc-600'>Co-founder @ Stealth</span>, solo-developed SaaS to 600+ paying users incl. Kellogg School of Management (acquired Nov 2024)</p>
          </li>
          <li className="mb-3">
            <p className='font-semibold'>Technology Investment Banking Summer Analyst @ Morgan Stanley</p>
          </li>
          <li className="mb-3">
            <p className='text-zinc-700'><span className='font-semibold text-zinc-600'>Co-founder @ Synced</span>, built a new way to apply to summer programs and internships (<a className="underline hover:text-zinc-600" href='https://www.crimsoneducation.org/us/' target='_blank'>acquired</a> Summer 2022)</p>
          </li>
          <li className="mb-3">
          <p className='text-zinc-700'><span className='font-semibold text-zinc-600'>Software Engineering Intern @ Facebook</span>, built a <a className="underline hover:text-zinc-600" href='/facebook' target='_blank'>social crypto investing app</a></p>
          </li>
          <li className="mb-3">
          <p className='text-zinc-700'><span className='font-semibold text-zinc-600'>Scout @ <a className="underline hover:text-zinc-600" href='https://www.draper.vc/' target='_blank'>Draper Associates</a></span>, sourced pre-seed + seed stage startups</p>
          </li>
          <li className="mb-3">
          <p className='text-zinc-700'><span className='font-semibold text-zinc-600'>Private Equity Intern @ <a className="underline hover:text-zinc-600" href='https://www.hunterst.com/' target='_blank'>Hunter Street Partners</a></span>, researched M&A markets</p>
          </li>
          <li className="mb-3">
          <p className='text-zinc-700'><span className='font-semibold text-zinc-600'>Co-founder @ <a className="underline hover:text-zinc-600" href='/byt' target='_blank'>Byt</a></span>, connected users with small-business restaurants through SMS</p>
          </li>
        </ul>
      </div>

      <div className="flex justify-center mb-6 animate-fadein">
        <p className=" mx-2">[ <a className="hover:text-zinc-700" href="/">home</a> ]</p>
      </div>
    </div>
  );
}

export default Experience;
